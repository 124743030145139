const origin = window.location.origin;

export const AUTH_BASE_URL =
	origin.includes(':3000') || origin.includes('dev') ? 'https://dev.auth.pi-labs.eu' : 'https://auth.pi-labs.eu';

export const BASE_URL =
	origin.includes(':3000') || origin.includes('dev') ? 'https://dev.adh.pi-labs.eu' : 'https://adh.pi-labs.eu';

export const TOKEN_URL = `${AUTH_BASE_URL}/oauth2/token`;
export const REFRESH_URL = `${AUTH_BASE_URL}/oauth2/refresh`;
export const LOGOUT_URL = `${AUTH_BASE_URL}/oauth2/logout`;

export const API_TOKEN = `${BASE_URL}/api/v1/token`;
export const FIRMWARE_URL = `${BASE_URL}/api/v1/firmware`;
