import keys from './ActionTypeKeys';
import { ISignIn, ISignOut } from './IActions';
import { authenticate, signOut as callSignOut } from '../api/auth';

export const signIn = (): ISignIn => ({
	type: keys.SIGN_IN,
	payload: authenticate(),
});

export const signOut = (): ISignOut => ({
	type: keys.SIGN_OUT,
	payload: callSignOut(),
});
