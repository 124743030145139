import React from 'react';
import { Navigate, Route, Routes as InternalRoutes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Loading from '../pages/Loading';
import EditGroup from '../groups/EditGroup';
import GroupListTable from '../groups/GroupListTable';
import UploadProtocol from '../installation/UploadProtocol';

// USER ROLES
const ROOT = 'Root';
const ADMIN = 'Admin';
const FLEET_MANAGER = 'FleetManager';
const FLEET_USER = 'FleetUser';
const TECHNICIAN = 'Technician';

export const routes = {
	home: { uri: '/', label: 'Home', roles: [ROOT, ADMIN, FLEET_MANAGER, FLEET_USER] },
	groups: { uri: '/groups', label: 'Groups', roles: [ROOT, ADMIN, FLEET_MANAGER, FLEET_USER] },
	installation: { uri: '/installation', label: 'Installation', roles: [TECHNICIAN] },
};

export interface IRoute {
	userRoles: Array<string>;
}

interface IProtectedRoute {
	roles: string[];
	userRoles: string[];
	element: () => JSX.Element;
}

const ProtectedRoute = ({ roles, userRoles, element: C, ...rest }: IProtectedRoute) => {
	const isSignedIn = Boolean(userRoles.length);

	const isTechnician = userRoles.length === 1 && userRoles[0] === TECHNICIAN;

	if (isSignedIn && roles.some((r) => userRoles.includes(r)) && C)
		// @ts-ignore
		return <C {...rest} />;
	else if (isSignedIn) return <Navigate to={isTechnician ? '/installation' : '/'} />;
	else {
		return <Loading redirect={true} />;
	}
};

const Routes = (props: IRoute) => (
	<InternalRoutes>
		<Route
			path={routes.home.uri}
			element={<ProtectedRoute roles={routes.home.roles} userRoles={props.userRoles} element={HomePage} />}
		/>
		<Route
			path={routes.groups.uri}
			element={
				<ProtectedRoute roles={routes.groups.roles} userRoles={props.userRoles} element={GroupListTable} />
			}
		/>
		<Route
			path={`${routes.groups.uri}/create`}
			element={<ProtectedRoute roles={routes.groups.roles} userRoles={props.userRoles} element={EditGroup} />}
		/>
		<Route
			path={`${routes.groups.uri}/:groupId`}
			element={<ProtectedRoute roles={routes.groups.roles} userRoles={props.userRoles} element={EditGroup} />}
		/>
		<Route
			path={routes.installation.uri}
			element={
				<ProtectedRoute
					roles={routes.installation.roles}
					userRoles={props.userRoles}
					element={UploadProtocol}
				/>
			}
		/>
	</InternalRoutes>
);

export default Routes;
