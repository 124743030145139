export const PilabsBlue = {
	50: '#E2FAFF',
	100: '#AFECFF',
	200: '#7AD8FF',
	300: '#48C9FF',
	400: '#00BAFF',
	500: '#00AEFE',
	600: '#009FEF',
	700: '#028EDE',
	800: '#007AC7',
	900: '#005CA8',
	A100: '#7DDEFF',
	A200: '#3EC9FF',
	A400: '#00B7FF',
	A700: '#1497F7',
};
