import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
	spinner: {
		border: 'solid',
		borderRadius: '50%',
		animation: `$spin 1s linear infinite`,
	},
	'@keyframes spin': {
		from: {
			transform: 'rotateZ(0deg)',
		},
		to: {
			transform: 'rotateZ(360deg)',
		},
	},
});

export type SpinnerProps = {
	size?: number;
	className?: string;
	primaryColor?: string;
	secondaryColor?: string;
	borderWidth?: string;
	children?: JSX.Element | Array<JSX.Element | null | string> | null | string;
};

const Spinner = ({
	size = 2,
	className = '',
	primaryColor,
	secondaryColor,
	borderWidth = '.25rem',
	children,
}: SpinnerProps) => {
	const theme = useTheme();
	const classes = useStyles();
	const pColor = primaryColor ? primaryColor : theme.palette.primary.main;
	const sColor = secondaryColor ? secondaryColor : theme.palette.primary.light;
	return (
		<>
			<div
				className={`${classes.spinner} ${className}`}
				style={{
					height: `${size}rem`,
					width: `${size}rem`,
					borderColor: `${pColor} ${pColor} ${pColor} ${sColor}`,
					borderWidth: borderWidth,
				}}
			/>
			{children}
		</>
	);
};

export default Spinner;
