import React, { ReactElement } from 'react';
import MuiChip from '@mui/material/Chip';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export type ChipVariation = 'warning' | 'success' | 'danger';

export type ChipProps = {
	/** The text to display. */
	text: string;
	/** The type of chip to display. */
	variation?: ChipVariation;
	/** The names of optional CSS classes to be added to the component. */
	className?: string;
};

/**
 * A component that renders a compact text element called Chip.
 */
export const Chip = ({ text, variation, className }: ChipProps) => {
	let chipColor:
		| 'default'
		| 'primary'
		| 'secondary'
		| 'info'
		| 'warning'
		| 'success'
		| 'error'
		| undefined = undefined;
	let chipIcon: ReactElement | undefined = undefined;
	switch (variation) {
		case 'warning':
			chipColor = 'warning';
			chipIcon = <WarningAmberIcon />;
			break;
		case 'danger':
			chipColor = 'error';
			chipIcon = <ErrorOutlineOutlinedIcon />;
			break;
		case 'success':
		default:
			chipColor = 'success';
			chipIcon = <CheckCircleOutlineIcon />;
			break;
	}

	return (
		<>
			<MuiChip
				label={text}
				variant="filled"
				color={chipColor}
				icon={chipIcon}
				size="small"
				style={{ padding: '0 0.5em' }}
				className={className}
			/>
		</>
	);
};
