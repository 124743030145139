enum ActionTypeKeys {
	SIGN_IN = 'SIGN_IN',
	SIGN_IN_PENDING = 'SIGN_IN_PENDING',
	SIGN_IN_RESOLVED = 'SIGN_IN_RESOLVED',
	SIGN_IN_REJECTED = 'SIGN_IN_REJECTED',

	SIGN_OUT = 'SIGN_OUT',
	SIGN_OUT_PENDING = 'SIGN_OUT_PENDING',
	SIGN_OUT_RESOLVED = 'SIGN_OUT_RESOLVED',
	SIGN_OUT_REJECTED = 'SIGN_OUT_REJECTED',

	LIST_GROUPS = 'LIST_GROUPS',
	LIST_GROUPS_PENDING = 'LIST_GROUPS_PENDING',
	LIST_GROUPS_RESOLVED = 'LIST_GROUPS_RESOLVED',
	LIST_GROUPS_REJECTED = 'LIST_GROUPS_REJECTED',

	GET_GROUP = 'GET_GROUP',
	GET_GROUP_PENDING = 'GET_GROUP_PENDING',
	GET_GROUP_RESOLVED = 'GET_GROUP_RESOLVED',
	GET_GROUP_REJECTED = 'GET_GROUP_REJECTED',

	UPDATE_GROUP = 'UPDATE_GROUP',
	UPDATE_GROUP_PENDING = 'UPDATE_GROUP_PENDING',
	UPDATE_GROUP_RESOLVED = 'UPDATE_GROUP_RESOLVED',
	UPDATE_GROUP_REJECTED = 'UPDATE_GROUP_REJECTED',

	CREATE_GROUP = 'CREATE_GROUP',
	CREATE_GROUP_PENDING = 'CREATE_GROUP_PENDING',
	CREATE_GROUP_RESOLVED = 'CREATE_GROUP_RESOLVED',
	CREATE_GROUP_REJECTED = 'CREATE_GROUP_REJECTED',

	UPLOAD_PROTOCOL = 'UPLOAD_PROTOCOL',
	UPLOAD_PROTOCOL_PENDING = 'UPLOAD_PROTOCOL_PENDING',
	UPLOAD_PROTOCOL_RESOLVED = 'UPLOAD_PROTOCOL_RESOLVED',
	UPLOAD_PROTOCOL_REJECTED = 'UPLOAD_PROTOCOL_REJECTED',

	QUERY_VEHICLE_INFO = 'QUERY_VEHICLE_INFO',
	QUERY_VEHICLE_INFO_PENDING = 'QUERY_VEHICLE_INFO_PENDING',
	QUERY_VEHICLE_INFO_RESOLVED = 'QUERY_VEHICLE_INFO_RESOLVED',
	QUERY_VEHICLE_INFO_REJECTED = 'QUERY_VEHICLE_INFO_REJECTED',
}

export default ActionTypeKeys;
