// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BRdMnISOMJd96U3KQ13wqQ\\=\\={width:100%;height:48px;display:flex;flex-direction:row;justify-content:space-between;align-items:center}.QJhQNyJv892sVj0hZUGoTw\\=\\=,.TsLf\\+siPCGiIhKuPrGB3Jg\\=\\=,.aveXrmThL9p96ON977hsKA\\=\\=,.AZe73DoDBYZtzEhCRxakKQ\\=\\=,.bpE8Ab\\+4ILtJ0DttxTfebQ\\=\\=,.Qhlb\\+VlUoyn21aacpfUy6Q\\=\\=,.hlWCs\\+SRBPJqbvZ6BzCDhQ\\=\\=,.xFVHepMYggeFgbIEAltbiA\\=\\={display:inline-flex;height:48px}.QJhQNyJv892sVj0hZUGoTw\\=\\={min-width:116px;flex-direction:row;justify-content:flex-start;align-items:center}.QJhQNyJv892sVj0hZUGoTw\\=\\=>img{max-height:24px}.bpE8Ab\\+4ILtJ0DttxTfebQ\\=\\=,.Qhlb\\+VlUoyn21aacpfUy6Q\\=\\=,.hlWCs\\+SRBPJqbvZ6BzCDhQ\\=\\=,.xFVHepMYggeFgbIEAltbiA\\=\\={flex-direction:row;justify-content:center;align-items:center}.Nd3ERZnEodLlzYEFzYKKPw\\=\\={height:32px;width:1px;margin:0 32px 0 16px;box-sizing:border-box}.Qhlb\\+VlUoyn21aacpfUy6Q\\=\\=,.hlWCs\\+SRBPJqbvZ6BzCDhQ\\=\\=,.xFVHepMYggeFgbIEAltbiA\\=\\={color:var(--cold-neutral-color);text-transform:uppercase}.hlWCs\\+SRBPJqbvZ6BzCDhQ\\=\\=,.xFVHepMYggeFgbIEAltbiA\\=\\={color:var(--primary-color)}.xFVHepMYggeFgbIEAltbiA\\=\\={margin-left:8px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BRdMnISOMJd96U3KQ13wqQ==",
	"logoContainer": "QJhQNyJv892sVj0hZUGoTw==",
	"main": "TsLf+siPCGiIhKuPrGB3Jg==",
	"menu": "aveXrmThL9p96ON977hsKA==",
	"userInfo": "AZe73DoDBYZtzEhCRxakKQ==",
	"separatorContainer": "bpE8Ab+4ILtJ0DttxTfebQ==",
	"user": "Qhlb+VlUoyn21aacpfUy6Q==",
	"userHighlight": "hlWCs+SRBPJqbvZ6BzCDhQ==",
	"userIcon": "xFVHepMYggeFgbIEAltbiA==",
	"separator": "Nd3ERZnEodLlzYEFzYKKPw=="
};
export default ___CSS_LOADER_EXPORT___;
