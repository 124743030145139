import { AxiosError } from 'axios';

const handleError = (error: AxiosError) => {
	console.log('ERROR:', error.response?.data);
	if (error.response) {
		const { data } = error.response;
		let message = '';
		if (data && Array.isArray(data)) {
			message = data[0].message;
		} else {
			// @ts-ignore
			message = data && data.message ? data.message : 'Unknown error';
		}
		return new Error(message);
	}
	return error;
};

export default handleError;
