import { Reducer } from 'redux';
import keys from '../actions/ActionTypeKeys';
import ActionTypes from '../actions/ActionTypes';
import initialState from '../store/initialState';
import GroupState from '../model/GroupState';

const groups: Reducer<GroupState, ActionTypes> = (state = initialState.groups, action): GroupState => {
	switch (action.type) {
		case keys.LIST_GROUPS_RESOLVED:
			return { ...state, list: action.payload, loading: false };
		case keys.LIST_GROUPS_PENDING:
			return { ...state, loading: true };
		default:
			return state;
	}
};

export default groups;
