export const T60Green = {
	50: '#D3D6D4',
	100: '#B4CAB8',
	200: '#8EB995',
	300: '#67AB73',
	400: '#4AA05B',
	500: '#269543',
	600: '#1E873B',
	700: '#10742F',
	800: '#006325',
	900: '#004716',
	A100: '#A4D9B7',
	A200: '#53D39B',
	A400: '#00CB6A',
	A700: '#32A95B',
};
