import React from 'react';

/**
 * A hook for retrieving the previous value for a specific prop.
 */
export function usePrevious<T>(value: T): T {
	const ref = React.useRef<T>();
	React.useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current as T;
}
