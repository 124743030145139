import React, { ReactNode } from 'react';
import styles from './TopBar.module.scss';

export type TopBarProps = {
	/** The components to be rendered as children of this component. */
	children: ReactNode;
};

/**
 * A component that renders the standard top bar and wraps other components.
 *
 * It is rendered at the top of the screen, occupies the whole browser viewport width and can contain any kind of component (menubar, images, etc.).
 */
export const TopBar = ({ children }: TopBarProps) => {
	return (
		<>
			<div className={styles.container}>{children}</div>
		</>
	);
};
