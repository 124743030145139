import axios from './interceptors';
import { BASE_URL } from './constants';
import Group from '../model/Group';
import handleError from './handle-error';

export const listGroups = () => {
	return axios
		.get(`${BASE_URL}/api/v1/groups`)
		.then((response) => {
			return response.data.map((group: Group) => ({ ...group, devices: group.devices.map((vin) => ({ vin })) }));
		})
		.catch((error) => {
			throw handleError(error);
		});
};

export const getGroup = (groupId: string) => {
	return axios
		.get(`${BASE_URL}/api/v1/groups/${groupId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};

export const updateGroup = (group: FormData) => {
	return axios
		.put(`${BASE_URL}/api/v1/groups/${group.get('id')}`, group)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};

export const createGroup = (group: FormData) => {
	return axios
		.post(`${BASE_URL}/api/v1/groups`, group)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw handleError(error);
		});
};

export const deleteGroup = (groupId: string) => {
	return axios
		.delete(`${BASE_URL}/api/v1/groups/${groupId}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};

export const getConfigurationVersion = async (groupId: string, versionId: string) => {
	const response = await axios.get(`${BASE_URL}/api/v1/groups/${groupId}/configuration/${versionId}`);
	return response.data;
};

export const getVinConfiguration = async (vin: string) => {
	const response = await axios.get(`${BASE_URL}/api/v1/vin/configuration/${vin}`);
	return response.data;
};
