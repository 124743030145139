import IStore from './IStore';

const initialState: IStore = {
	user: {
		info: undefined,
	},
	groups: { list: [], loading: false },
	loading: false,
};

export default initialState;
