import React, { ReactNode, forwardRef, ForwardedRef } from 'react';
import MuiToolbar from '@mui/material/Toolbar';
import MuiTypography from '@mui/material/Typography';
import MuiTooltip from '@mui/material/Tooltip';
import MuiIconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { ColumnConfig } from './Table';

export type TableToolbarProps<DataType> = {
	/** The table title to display in the Toolbar. */
	title: string;
	/** The configuration for the table columns. */
	columnsConfig: readonly ColumnConfig<DataType>[];
	/** The number of currently selected rows. */
	numberOfSelectedRows: number;
	/** The icon to display on the top right when at least one row is selected. */
	selectedRowIcon?: ReactNode;
	/** The icon tooltip to display on the top right when at least one row is selected. */
	selectedRowIconLabel?: string;
	/** whether the filter is active. */
	isFilterActive: boolean;
	/** A callback function for when the filter function is toggled. */
	onToggleFilter: () => void;
};

/**
 * The Toolbar component for the table.
 */
// eslint-disable-next-line react/display-name
export const TableToolbar = forwardRef(
	<DataType,>(
		{
			title,
			columnsConfig,
			numberOfSelectedRows,
			selectedRowIcon,
			selectedRowIconLabel,
			isFilterActive,
			onToggleFilter,
		}: TableToolbarProps<DataType>,
		ref: ForwardedRef<ReactNode>,
	) => {
		const handleOnClickFilterButton = () => {
			onToggleFilter();
		};

		let rightIcon: ReactNode = null;
		if (numberOfSelectedRows > 0) {
			if (selectedRowIcon) {
				rightIcon = (
					<MuiTooltip title={selectedRowIconLabel || ''}>
						<MuiIconButton>{selectedRowIcon}</MuiIconButton>
					</MuiTooltip>
				);
			}
		} else {
			const isFilterEnabled: boolean = columnsConfig
				.map((columnConfig: ColumnConfig<DataType>) => !!columnConfig.filter)
				.includes(true);
			rightIcon = isFilterEnabled ? (
				<MuiTooltip
					title={isFilterActive ? 'Close filter' : 'Open filter'}
					enterDelay={600}
					enterNextDelay={600}
				>
					<MuiIconButton onClick={handleOnClickFilterButton}>
						{isFilterActive ? <FilterListOffIcon /> : <FilterListIcon />}
					</MuiIconButton>
				</MuiTooltip>
			) : null;
		}

		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			<MuiToolbar
				ref={ref}
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					...(numberOfSelectedRows > 0 && {
						bgcolor: (theme) =>
							alpha(
								theme.palette.primary.main,
								theme.palette.action.activatedOpacity,
							),
					}),
				}}
			>
				{numberOfSelectedRows > 0 ? (
					<MuiTypography
						sx={{ flex: '1 1 100%' }}
						color="inherit"
						variant="subtitle1"
						component="div"
					>
						{numberOfSelectedRows} selected
					</MuiTypography>
				) : (
					<MuiTypography
						sx={{ flex: '1 1 100%' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						{title}
					</MuiTypography>
				)}
				{rightIcon}
			</MuiToolbar>
		);
	},
);
