import { createStore, applyMiddleware } from 'redux';
import reducer from '../reducers/index';
import IStore from './IStore';
// @ts-ignore
import { createLogger } from 'redux-logger';
import ActionTypes from '../actions/ActionTypes';
import { handlePromises } from './middleware';

const middlewares: any = [handlePromises];

if (process.env.NODE_ENV === 'development') {
	middlewares.push(createLogger());
}

export default createStore<IStore, ActionTypes, any, any>(reducer, applyMiddleware(...middlewares));
