import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { BiHomeAlt, BiChevronRight } from 'react-icons/bi';
import { RouteItem } from '../../types';
import styles from './RouteBreadcrumb.module.scss';

const ROOT_PATH = '/';

export type BreadcrumbProps = {
	crumbList: RouteItem[];
};

export const RouteBreadcrumb = ({ crumbList }: BreadcrumbProps) => {
	return (
		<>
			<div className={styles.container}>
				<Link key="home" className={styles.clearAnchor} to={ROOT_PATH}>
					<BiHomeAlt
						className={classnames([styles.menuItem, styles.breadcrumbHome])}
					/>
				</Link>
				{crumbList
					.filter((routeItem: RouteItem) => routeItem.uri !== ROOT_PATH)
					.map((routeItem: RouteItem, index: number) => (
						<div key={`breadcrumb${index}`} className={styles.menuItem}>
							<BiChevronRight className={styles.arrow} />
							<Link className={styles.clearAnchor} to={routeItem.uri}>
								{routeItem.label}
							</Link>
						</div>
					))}
			</div>
		</>
	);
};
