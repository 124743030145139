import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { pilabsTheme, ThemeProvider } from '@easerill/pixida-group-ui';
import reportWebVitals from './reportWebVitals';
import store from './store';

import MainThemeProvider from './components/themes/MainThemeProvider';

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<MainThemeProvider>
					<ThemeProvider themeOptions={pilabsTheme}>
						<App />
					</ThemeProvider>
				</MainThemeProvider>
			</Provider>
		</React.StrictMode>,
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
