import React, { useEffect } from 'react';
import { useNavigate as useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import { makeStyles } from '@mui/styles';
import { Button, ColumnConfig, RowData, Table, Tooltip } from '@easerill/pixida-group-ui';
import { EditIcon, RotateRightIcon } from '../material-table/icons';
import PlusIcon from '../../assets/icons/PlusIcon';
import IStore from '../../store/IStore';
import Group from '../../model/Group';
import Layout from '../layout/Layout';
import GroupListDetails from './GroupListDetails';
import { listGroupsAction } from '../../actions/GroupActions';

const useStyles = makeStyles((theme: any) => ({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	table: {
		flex: 1,
	},
	buttonPadding: {
		padding: '16px 0px 16px 0px',
	},
	divColumnName: {
		display: 'grid',
	},
	spanName: {
		fontSize: '0.75rem',
		letterSpacing: '0.1px',
		color: theme.palette.grey['900'],
	},
	spanMetadata: {
		fontSize: '0.75rem',
		letterSpacing: '0.1px',
		color: theme.palette.grey['500'],
	},
}));

const GroupListTable = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const groups: Group[] = useSelector<IStore, Group[]>((state) => state.groups.list);
	const loading: boolean = useSelector<IStore, boolean>((state) => state.groups.loading);

	useEffect(() => {
		dispatch(listGroupsAction());
	}, [dispatch]);

	const handleTriggerCreateNewGroup = () => {
		history('/groups/create');
	};

	const tableData: Group[] = groups;

	const columnsConfig: readonly ColumnConfig<Group>[] = [
		{
			id: 'name',
			label: 'Name',
			align: 'left',
			filter: true,
			disablePadding: true,
			render: (rowData: RowData<Group>) => {
				const metadataObject = JSON.parse(rowData.data.metadata || '{}');
				const metadata = Object.entries(metadataObject)
					.map((k) => `${k[0]}: ${k[1]}`)
					.join(' | ');
				return (
					<div className={classes.divColumnName}>
						<span className={classes.spanName}>{rowData.data.name}</span>
						<span className={classes.spanMetadata}>{metadata}</span>
					</div>
				);
			},
		},
		{
			id: 'devices',
			label: '# Devices',
			align: 'center',
			render: (rowData: RowData<Group>) => rowData.data.devices.length,
		},
		{
			id: 'modifiedAt',
			label: 'Modified at',
			align: 'center',
			render: (rowData: RowData<Group>) =>
				rowData.data.modifiedAt !== undefined
					? dateFormat(new Date(rowData.data.modifiedAt * 1000).toISOString(), 'dd-mmm-yyyy HH:MM')
					: '-',
		},
		{
			id: 'updatedDevices',
			label: '',
			align: 'right',
			render: (rowData: RowData<Group>) => {
				let hasOngoing = false;
				if (rowData.data.updatedDevices) {
					hasOngoing = rowData.data.updatedDevices.length !== rowData.data.devices.length;
				} else {
					hasOngoing = rowData.data.devices.length > 0;
				}

				return hasOngoing ? (
					<Tooltip title="Ongoing update">
						<RotateRightIcon />
					</Tooltip>
				) : undefined;
			},
		},
	];

	return (
		<Layout>
			<div className={classes.container}>
				<div className={classes.buttonPadding}>
					<Button text="New Group" startIcon={<PlusIcon />} onClick={handleTriggerCreateNewGroup} />
				</div>

				<div className={classes.table}>
					<Table
						title="Groups"
						columnsConfig={columnsConfig}
						data={tableData}
						isLoading={loading}
						rowsPerPageOptions={[5, 10, 20]}
						defaultRowsPerPage={10}
						allowCollapsibleRows={true}
						renderCollapsibleRow={(rowData: RowData<Group>) => (
							<GroupListDetails
								devices={rowData.data.devices}
								updatedDevices={rowData.data.updatedDevices}
							/>
						)}
						collapsibleButtonColumnPosition={'first'}
						defaultOrderBy={'modifiedAt'}
						defaultOrder={'desc'}
						rowActions={[
							{
								icon: <EditIcon />,
								tooltip: 'Edit',
								showOnlyOnRowHover: true,
								onClick: (rowData: RowData<Group>) => {
									history(`/groups/${rowData.data.id}`);
								},
							},
						]}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default GroupListTable;
