import React from 'react';
import MuiButton from '@mui/material/Button';

export type ButtonVariation =
	| 'default'
	| 'ghost'
	| 'success'
	| 'info'
	| 'danger';

export type ButtonSize = 'small' | 'medium' | 'large';

export type ButtonProps = {
	/** The text to display. */
	text: string;
	/** The button variation. */
	variation?: ButtonVariation;
	/** The button size. */
	size?: ButtonSize;
	/** The icon to be displayed before the button text. */
	startIcon?: React.ReactNode;
	/** The icon to be displayed after the button text. */
	endIcon?: React.ReactNode;
	/** Whether the button is disabled. */
	disabled?: boolean;
	/** A callback function executed when the button is clicked. */
	onClick?: () => void;
	/** The names of optional CSS classes to be added to the component. */
	className?: string;
};

/**
 * A component that renders a button.
 */
export const Button = ({
	text,
	variation = 'default',
	size,
	startIcon,
	endIcon,
	disabled = false,
	onClick,
	className,
}: ButtonProps) => {
	let buttonVariant: 'contained' | 'outlined' | 'text' = 'contained';

	let buttonColor:
		| 'inherit'
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning'
		| undefined = 'primary';

	let buttonStyle: React.CSSProperties = {
		':hover': {
			backgroundColor: 'primary.light' /* theme.palette.primary.light */,
		},
	} as React.CSSProperties;

	switch (variation) {
		case 'ghost':
			buttonVariant = 'text';
			buttonStyle = {
				'&.MuiButton-text': {
					color: 'info.main' /* theme.palette.info.main */,
				},
				':hover': {
					backgroundColor: 'grey.50' /* theme.palette.grey.50 */,
				},
			} as React.CSSProperties;
			break;
		case 'success':
			buttonColor = 'success';
			buttonStyle = {
				':hover': {
					backgroundColor: 'success.light' /* theme.palette.success.light */,
				},
			} as React.CSSProperties;
			break;
		case 'info':
			buttonColor = 'info';
			buttonStyle = {
				':hover': {
					backgroundColor: 'info.light' /* theme.palette.info.light */,
				},
			} as React.CSSProperties;
			break;
		case 'danger':
			buttonColor = 'error';
			buttonStyle = {
				':hover': {
					backgroundColor: 'error.light' /* theme.palette.error.light */,
				},
			} as React.CSSProperties;
			break;
		case 'default':
		default:
			break;
	}

	const handleOnCLick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<>
			<MuiButton
				variant={buttonVariant}
				size={size}
				color={buttonColor}
				sx={buttonStyle}
				startIcon={startIcon}
				endIcon={endIcon}
				onClick={handleOnCLick}
				disabled={disabled}
				style={{
					whiteSpace: 'nowrap',
					minWidth: 'max-content',
				}}
				className={className}
			>
				{text}
			</MuiButton>
		</>
	);
};
