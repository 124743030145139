export const PixidaRed = {
	50: '#F5DBDF',
	100: '#F2BDC2',
	200: '#E08989',
	300: '#D56364',
	400: '#DF4340',
	500: '#E12F26',
	600: '#D22426',
	700: '#C01120',
	800: '#B40A19',
	900: '#A6000C',
	A100: '#F07C70',
	A200: '#ED3E42',
	A400: '#EE0037',
	A700: '#C30000',
};

export const PixidaBlue = {
	50: '#D7DDDF',
	100: '#A0B3B8',
	200: '#7799A3',
	300: '#518DA0',
	400: '#37809C',
	500: '#1E769B',
	600: '#15698E',
	700: '#08597E',
	800: '#00486B',
	900: '#002E50',
	A100: '#7A9FA9',
	A200: '#498DA5',
	A400: '#1E7EA6',
	A700: '#006395',
};
