// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P5XbRq\\+l8ugWV1--qTIwPw\\=\\={width:100%;min-height:1.5em;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;align-items:center}.ImXWVsK-6GvsT5sN3Ht8Vg\\=\\={width:100%}.LiSHE\\+Id82T8hcQip3B3AQ\\=\\={margin-left:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "P5XbRq+l8ugWV1--qTIwPw==",
	"progressBar": "ImXWVsK-6GvsT5sN3Ht8Vg==",
	"label": "LiSHE+Id82T8hcQip3B3AQ=="
};
export default ___CSS_LOADER_EXPORT___;
