import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { CorporateLogo } from '../CorporateLogo';
import { TabMenu, TabMenuEntry } from '../TabMenu';
import { ColdNeutral } from '../../colors';
import styles from './Menubar.module.scss';

export type MenubarProps = {
	/** A list containing the menu items to be displayed. */
	menu: TabMenuEntry[];
	/** The index of the currently selected item. */
	selectedItem: number;
	/** A callback function that informs the index of the new selected item. */
	onChange: (selectedItem: number) => void;
	/** Whether the theme logo should be displayed. */
	logo?: boolean;
	/** The username for the currently logged-in user. */
	username?: string;
};

/**
 * A component that renders a menubar.
 */
export const Menubar = ({
	menu,
	selectedItem,
	onChange,
	logo,
	username,
}: MenubarProps) => {
	const theme = useMuiTheme();

	const themeStyle = {
		'--primary-color': theme.palette.primary.main,
		'--cold-neutral-color': ColdNeutral[500],
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		lineHeight: '16px',
		letterSpacing: '1.25px',
	} as React.CSSProperties;

	return (
		<>
			<MaxWidthContainer>
				<div className={styles.container}>
					<div className={styles.logoContainer}>
						{logo && <CorporateLogo />}
					</div>
					<div className={styles.main}>
						<div className={styles.menu}>
							<TabMenu
								menu={menu}
								selectedItem={selectedItem}
								onChange={onChange}
							/>
						</div>
						{username && (
							<div className={styles.userInfo}>
								<div className={styles.separatorContainer}>
									<div className={styles.separator} style={themeStyle} />
								</div>
								<MuiTypography
									component="span"
									className={styles.user}
									sx={themeStyle}
								>
									Hello,&nbsp;
								</MuiTypography>
								<MuiTypography
									component="span"
									className={styles.userHighlight}
									sx={themeStyle}
								>
									{username}
								</MuiTypography>
								<MuiTypography
									component="span"
									className={styles.userIcon}
									sx={themeStyle}
								>
									<PersonOutlineIcon />
								</MuiTypography>
							</div>
						)}
					</div>
				</div>
			</MaxWidthContainer>
		</>
	);
};
