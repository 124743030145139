import React, { createContext, ReactNode } from 'react';
import {
	CssBaseline,
	GlobalStyles,
	ThemeOptions as MuiThemeOptions,
} from '@mui/material';
import {
	createTheme as muiCreateTheme,
	Theme as MuiTheme,
	ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { pixidaTheme as defaultTheme } from '../../themes/pixidaTheme';
import { ThemeOptions } from './ThemeOptions';

export type ThemeContextType = {
	themeOptions: ThemeOptions;
};

export type ThemeProviderProps = {
	/** The theme options to be used in the application. */
	themeOptions: ThemeOptions;
	/** The components to be rendered as children of this component. */
	children: ReactNode;
};

export const ThemeContext = createContext<ThemeContextType>({
	themeOptions: defaultTheme,
});

const createTheme = (themeOptions: ThemeOptions): MuiTheme => {
	const commonMuiThemeOptions: MuiThemeOptions = {
		typography: {
			fontFamily: ['Noto Sans', 'Noto Sans Mono', 'sans-serif'].join(','),
			fontSize: 14,
		},
	};

	const muiThemeOptions: MuiThemeOptions = {
		palette: {
			primary: {
				main: themeOptions.palette.primary.main,
				contrastText: themeOptions.palette.primary.contrastText,
			},
			secondary: {
				main: themeOptions.palette.primary.main,
				contrastText: themeOptions.palette.primary.contrastText,
			},
			info: {
				main: themeOptions.palette.info.main,
				contrastText: themeOptions.palette.info.contrastText,
			},
			success: {
				main: themeOptions.palette.success.main,
				contrastText: themeOptions.palette.success.contrastText,
			},
			warning: {
				main: themeOptions.palette.warning.main,
				contrastText: themeOptions.palette.warning.contrastText,
			},
			error: {
				main: themeOptions.palette.error.main,
				contrastText: themeOptions.palette.error.contrastText,
			},
		},
	};

	return muiCreateTheme(deepmerge(commonMuiThemeOptions, muiThemeOptions));
};

/**
 * A Provider responsible for theming the application.
 * It should wrap most of the app.
 */
export const ThemeProvider = ({
	themeOptions,
	children,
}: ThemeProviderProps) => {
	const inputGlobalStyles = (
		<GlobalStyles
			styles={{
				body: {
					fontFamily: ['Noto Sans', 'Noto Sans Mono', 'sans-serif'].join(','),
					fontSize: '14px',
					lineHeight: '16px',
					letterSpacing: '1.25px',
				},
			}}
		/>
	);

	return (
		<ThemeContext.Provider value={{ themeOptions: themeOptions }}>
			<MuiThemeProvider theme={createTheme(themeOptions)}>
				<CssBaseline />
				{inputGlobalStyles}
				{children}
			</MuiThemeProvider>
		</ThemeContext.Provider>
	);
};
