import { createTheme, CssBaseline } from '@mui/material';
import NotoSansSrc from '../../assets/fonts/NotoSans-Medium.ttf';
import NotoSansMonoSrc from '../../assets/fonts/NotoSansMono-Medium.ttf';
import { ThemeProvider } from '@mui/styles';
import { adaptV4Theme } from '@mui/material/styles';
import React from 'react';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
	interface DefaultTheme extends Theme {}
}

const NotoSans = {
	fontFamily: 'NotoSans',
	fontStyle: 'normal',
	src: `
    local('NotoSans'),
    local('NotoSans-Regular'),
    local('NotoSans-Bold'),
    url(${NotoSansSrc}) format('truetype')
  `,
};

const NotoSansMono = {
	fontFamily: 'NotoSansMono',
	fontStyle: 'normal',
	src: `
    local('NotoSansMono'),
    url(${NotoSansMonoSrc}) format('truetype')
  `,
};

const theme = createTheme(
	adaptV4Theme({
		palette: {
			primary: {
				main: '#00BAFF',
				dark: '#00679C',
				light: '#4ECFFF',
				'900': '#00679C',
				'800': '#007EB8',
				'700': '#0094D1',
				'600': '#00A9EB',
				'500': '#00BAFF',
				'400': '#4ECFFF',
				'300': '#6FE3FF',
				'200': '#9BEDFF',
				'100': '#B9F2FF',
				'50': '#EBFBFF',
			},
			secondary: {
				main: '#003855',
				dark: '#000003',
				light: '#244965',
				'900': '#000003',
				'800': '#00031D',
				'700': '#001A30',
				'600': '#002A45',
				'500': '#003855',
				'400': '#244965',
				'300': '#3A5A73',
				'200': '#597188',
				'100': '#738B9E',
				'50': '#92A1B4',
			},
			error: {
				main: '#B00020',
				'900': '#B71C1A',
				'800': '#C52727',
				'700': '#D12C2E',
				'600': '#E43935',
				'500': '#F44336',
				'400': '#F0544F',
				'300': '#E47273',
				'200': '#ED9898',
				'100': '#FDCCD1',
				'50': '#FEEAED',
			},
			warning: {
				main: '#FFBD00',
				dark: '#CFA11E',
				light: '#FDC926',
				'900': '#3D3213',
				'800': '#64511D',
				'700': '#9C7E28',
				'600': '#CFA11E',
				'500': '#FFBD00',
				'400': '#FDC926',
				'300': '#FDD44D',
				'200': '#FDDF81',
				'100': '#FEEBB2',
				'50': '#FFF7E0',
			},
			success: {
				main: '#8BC34A',
				dark: '#7AB241',
				light: '#9DCC64',
				'900': '#336A1E',
				'800': '#568B2F',
				'700': '#699E37',
				'600': '#7AB241',
				'500': '#8BC34A',
				'400': '#9DCC64',
				'300': '#ADD580',
				'200': '#C3E0A5',
				'100': '#DCEDC8',
				'50': '#F1F8E9',
			},
			grey: {
				'900': '#2B3438',
				'800': '#3C494F',
				'700': '#4A5C65',
				'600': '#5A717B',
				'500': '#67808C',
				'400': '#7F949E',
				'300': '#95A6AE',
				'200': '#B5C0C5',
				'100': '#D4DBDE',
				'50': '#F2F4F5',
			},
		},
		typography: {
			fontFamily: ['NotoSans', 'sans-serif'].join(','),
			h1: {
				fontWeight: 200,
			},
			h2: {
				fontWeight: 300,
			},
			h3: {
				fontWeight: 300,
			},
			h4: {
				fontWeight: 300,
			},
			h5: {
				fontWeight: 400,
			},
			h6: {
				fontWeight: 500,
			},
			button: {
				fontWeight: 500,
			},
		},
		breakpoints: {
			values: { xs: 0, sm: 600, md: 960, lg: 1200, xl: 1920 },
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					'@font-face': [NotoSans, NotoSansMono],
				},
			},
			MuiButton: {
				contained: {
					color: 'white !important',
					boxShadow: 'none',
				},
			},
		},
	}),
);

type Props = {
	children: React.ReactNode | React.ReactNode[];
};

const MainThemeProvider = ({ children }: Props) => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

export default MainThemeProvider;
