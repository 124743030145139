// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vWtnXNSUZ2jkRG-N-0gFew\\=\\={position:sticky;top:0;left:0;width:100%;max-width:100%;min-height:48px;background-color:#fff;box-shadow:0px 4px 16px rgba(0,0,0,.14)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vWtnXNSUZ2jkRG-N-0gFew=="
};
export default ___CSS_LOADER_EXPORT___;
