import { ThemeOptions } from '../components/ThemeProvider/ThemeOptions';
import {
	AuxiliaryDanger,
	AuxiliarySuccess,
	AuxiliaryWarning,
	ColdNeutral,
	T60Green,
	white,
} from '../colors';
import { t60Logo } from '../logos';

export const t60Theme: ThemeOptions = {
	name: 't60',
	logo: t60Logo,
	palette: {
		primary: {
			main: T60Green[500],
			contrastText: white,
		},
		info: {
			main: ColdNeutral[200],
			contrastText: white,
		},
		success: {
			main: AuxiliarySuccess[500],
			contrastText: white,
		},
		warning: {
			main: AuxiliaryWarning[500],
			contrastText: white,
		},
		error: {
			main: AuxiliaryDanger[700],
			contrastText: white,
		},
	},
};
