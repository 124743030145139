// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unjUpZ3qkn3ESAkjLhWbwg\\=\\={width:100%;background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchText": "unjUpZ3qkn3ESAkjLhWbwg=="
};
export default ___CSS_LOADER_EXPORT___;
