/**
 * Function for reading an uploaded file content as a text string.
 * @param file
 * @param encoding
 */
export const readFileAsTextPromise = (
	file: Blob,
	encoding?: string,
): Promise<string | null> =>
	new Promise((resolve) => {
		const fileReader = new FileReader();
		fileReader.onload = (event: ProgressEvent<FileReader>) => {
			const fileContent: string | ArrayBuffer | null =
				event.target?.result || null;
			resolve(fileContent as string | null);
		};
		fileReader.readAsText(file, encoding);
	});

/**
 * Function for reading an uploaded file content as an Array Buffer.
 * @param file
 */
export const readFileAsArrayBufferPromise = (
	file: Blob,
): Promise<ArrayBuffer | null> =>
	new Promise((resolve) => {
		const fileReader = new FileReader();
		fileReader.onload = (event: ProgressEvent<FileReader>) => {
			const fileContent: string | ArrayBuffer | null =
				event.target?.result || null;
			resolve(fileContent as ArrayBuffer | null);
		};
		fileReader.readAsArrayBuffer(file);
	});

/**
 * Function for reading an uploaded file content as Data URL.
 * @param file
 */
export const readFileAsDataURLPromise = (file: Blob): Promise<string | null> =>
	new Promise((resolve) => {
		const fileReader = new FileReader();
		fileReader.onload = (event: ProgressEvent<FileReader>) => {
			const fileContent: string | ArrayBuffer | null =
				event.target?.result || null;
			resolve(fileContent as string | null);
		};
		fileReader.readAsDataURL(file);
	});
