import React, { useContext } from 'react';
import { ThemeContext, ThemeContextType } from '../ThemeProvider';

// eslint-disable-next-line @typescript-eslint/ban-types
export type CorporateLogoProps = {};

/**
 * A component that renders as an image the corporate logo specified in the theme.
 */
// eslint-disable-next-line no-empty-pattern
export const CorporateLogo = ({}: CorporateLogoProps) => {
	const { themeOptions } = useContext<ThemeContextType>(ThemeContext);

	return (
		<>
			<img src={themeOptions.logo} alt="Logo" />
		</>
	);
};
