import React, { createContext, ReactNode } from 'react';
import {
	Collapse as MuiCollapse,
	// useTheme as useMuiTheme,
} from '@mui/material';
// TODO The SnackbarProvider styling needs to be fixed.
// TODO @mui/styles/makeStyles is deprecated and does not work with React 18.
// import { makeStyles as makeMuiStyles } from '@mui/styles';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

const DEFAULT_MAX_SNACKBARS = 3;
const DEFAULT_AUTO_HIDE_DURATION = 6000;

export type SnackbarSeverity = 'info' | 'warning' | 'success' | 'error';

export type SnackbarOptions = {
	/** The maximum number of snackbars to display at a time. */
	maxSnackbars?: number;
	/** How much time to wait, in milliseconds, before hiding the Snackbar. */
	autoHideDuration?: number;
};

export type SnackbarProviderProps = SnackbarOptions & {
	/** The components to be rendered as children of this component. */
	children: ReactNode;
};

export type SnackbarContextType = {
	snackbarOptions: SnackbarOptions;
};

export const SnackbarContext = createContext<SnackbarContextType>({
	snackbarOptions: {
		maxSnackbars: DEFAULT_MAX_SNACKBARS,
		autoHideDuration: DEFAULT_AUTO_HIDE_DURATION,
	},
});

/**
 * A Provider used to dispatch snackbars.
 * It should wrap most of the app and be a descendant of the ThemeProvider.
 * To deal with Snackbars, use the __useSnackbar__ hook.
 */
export const SnackbarProvider = ({
	maxSnackbars = DEFAULT_MAX_SNACKBARS,
	autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
	children,
}: SnackbarProviderProps) => {
	// const theme = useMuiTheme();
	// const useMuiStyles = makeMuiStyles({
	// 	info: {
	// 		color: `${theme.palette.info.contrastText} !important`,
	// 		backgroundColor: `${theme.palette.info.main} !important`,
	// 	},
	// 	warning: {
	// 		color: `${theme.palette.warning.contrastText} !important`,
	// 		backgroundColor: `${theme.palette.warning.main} !important`,
	// 	},
	// 	success: {
	// 		color: `${theme.palette.success.contrastText} !important`,
	// 		backgroundColor: `${theme.palette.success.main} !important`,
	// 	},
	// 	error: {
	// 		color: `${theme.palette.error.contrastText} !important`,
	// 		backgroundColor: `${theme.palette.error.main} !important`,
	// 	},
	// });
	// const muiClasses = useMuiStyles();

	return (
		<SnackbarContext.Provider
			value={{
				snackbarOptions: {
					maxSnackbars: maxSnackbars,
					autoHideDuration: autoHideDuration,
				},
			}}
		>
			<NotistackSnackbarProvider
				maxSnack={maxSnackbars}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				classes={
					{
						// variantInfo: muiClasses.info,
						// variantWarning: muiClasses.warning,
						// variantSuccess: muiClasses.success,
						// variantError: muiClasses.error,
					}
				}
				TransitionComponent={MuiCollapse as any}
			>
				{children}
			</NotistackSnackbarProvider>
		</SnackbarContext.Provider>
	);
};
