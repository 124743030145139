export const AuxiliarySuccess = {
	50: '#F1F8E9',
	100: '#DCEDC8',
	200: '#C3E0A5',
	300: '#ADD580',
	400: '#9DCC64',
	500: '#8BC34A',
	600: '#7AB241',
	700: '#699E37',
	800: '#568B2F',
	900: '#336A1E',
};

export const AuxiliaryWarning = {
	50: '#FFF7E0',
	100: '#FEEBB2',
	200: '#FDDF81',
	300: '#FDD44D',
	400: '#FDC926',
	500: '#FFBD00',
	600: '#CFA11E',
	700: '#9C7E28',
	800: '#64511D',
	900: '#3D3213',
};

export const AuxiliaryDanger = {
	50: '#FEEAED',
	100: '#FDCCD1',
	200: '#ED9898',
	300: '#E47273',
	400: '#F0544F',
	500: '#F44336',
	600: '#E43935',
	700: '#D12C2E',
	800: '#C52727',
	900: '#B71C1A',
};

export const ColdNeutral = {
	50: '#F2F4F5',
	100: '#D4DBDE',
	200: '#B5C0C5',
	300: '#95A6AE',
	400: '#7F949E',
	500: '#67808C',
	600: '#5A717B',
	700: '#4A5C65',
	800: '#3C494F',
	900: '#2B3438',
};

export const white = '#FFFFFF';

export const black = '#000000';
