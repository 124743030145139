import React, { useContext } from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
	SnackbarKey as NotistackSnackbarKey,
	useSnackbar as useNotistackSnackbar,
} from 'notistack';
import { SnackbarContext, SnackbarContextType, SnackbarSeverity } from '..';

type ReturnType = {
	/** A function for triggering Snackbars */
	triggerSnackbar: (text: string, severity: SnackbarSeverity) => void;
};

/**
 * A hook for dealing with Snackbars.
 * It must be used together with the __SnackbarProvider__.
 */
export function useSnackbar(): ReturnType {
	const { snackbarOptions } = useContext<SnackbarContextType>(SnackbarContext);
	const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

	const muiTheme = useMuiTheme();

	const handleDismissSnackbar = (key: NotistackSnackbarKey) => () => {
		closeSnackbar(key);
	};

	const triggerSnackbar = (text: string, severity: SnackbarSeverity) => {
		enqueueSnackbar(text, {
			variant: severity,
			autoHideDuration: snackbarOptions.autoHideDuration,
			action: (key: NotistackSnackbarKey) => (
				<MuiIconButton onClick={handleDismissSnackbar(key)}>
					<CloseIcon
						fontSize={'small'}
						style={{
							color:
								muiTheme.palette[severity].contrastText ||
								muiTheme.palette.primary.contrastText,
						}}
					/>
				</MuiIconButton>
			),
		});
	};

	return {
		triggerSnackbar,
	};
}
