import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import MuiBox from '@mui/material/Box';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import MuiTablePagination from '@mui/material/TablePagination';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import { getRowDataComparator, Order, stableSort } from './common';
import { TableToolbar } from './TableToolbar';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';

export type RowData<DataType> = {
	id: number;
	data: DataType;
};

export type ColumnConfig<DataType> = {
	/** The identifier for this column. */
	id: keyof DataType;
	/** The label for this column. */
	label: string;
	/** An optional function that specifies how to render the column. */
	render?: (rowData: RowData<DataType>) => ReactNode | string;
	/** The alignment for this column. */
	align?: 'left' | 'center' | 'right' | 'justify';
	/** The date and time format to use if this column is of type Date. */
	dateTimeFormat?: string;
	/** Whether padding should be disabled for this table column. */
	disablePadding?: boolean;
	/** Whether to enable filtering by this column. Only available for properties of type __string__. */
	filter?: boolean;
	/** Sets the left padding applied to the cell. This property is ignored if `disablePadding` is true */
	paddingLeft?: string | number;
	/** Sets the right padding applied to the cell. This property is ignored if `disablePadding` is true */
	paddingRight?: string | number;
};

export type RowAction<DataType> = {
	icon: ReactNode;
	tooltip?: string;
	showOnlyOnRowHover?: boolean;
	onClick: (rowData: RowData<DataType>) => void;
};

export type ColumnFilterType<DataType> = {
	property: keyof DataType;
	filterText: string;
};

// export type ElementDimensions = {
// 	width: number;
// 	height: number;
// };

// export enum TableOverflowType {
// 	GrowParent = 'GrowParent',
// 	FitParent = 'FitParent',
// }

export type TableProps<DataType> = {
	/** The table title to display in the Toolbar. */
	title: string;
	/** The configuration for the table head cells and columns. */
	columnsConfig: readonly ColumnConfig<DataType>[];
	/** The data to be displayed as rows in the table. */
	data: DataType[];
	/** Whether the table data is still loading. */
	isLoading?: boolean;
	/** Whether table rows can be selected. */
	allowSelectRows?: boolean;
	/** The icon to display on the top right when at least one row is selected. */
	selectedRowToolbarIcon?: ReactNode;
	/** The icon tooltip to display on the top right when at least one row is selected. */
	selectedRowToolbarIconLabel?: string;
	/** Whether the table rows can be collapsed. */
	allowCollapsibleRows?: boolean;
	/** An optional function that specifies how to render a collapsible row. Required when setting __allowCollapsibleRows__ to __true__. */
	renderCollapsibleRow?: (rowData: RowData<DataType>) => ReactNode | string;
	/**
	 * Whether the collapsible button will be on the first (after row selection toggle) or the last column.
	 * @default last
	 */
	collapsibleButtonColumnPosition?: 'first' | 'last';
	/** The options for number of rows per page. */
	rowsPerPageOptions?: number[];
	/** The default number of rows per page. */
	defaultRowsPerPage?: number;
	/** Whether to disable the table pagination. All rows will be displayed at once. */
	disablePagination?: boolean;
	/** Whether to show the table's border. */
	showBorder?: boolean;
	/** Whether the table is initially displayed in a dense layout. */
	dense?: boolean;
	/** Whether a control is displayed for switching the table density. */
	densityControl?: boolean;
	/** A list of icon buttons to be displayed at the end of each row. */
	rowActions?: RowAction<DataType>[];
	/** The default column used for sorting the table. If not informed, the first column is used by default. */
	defaultOrderBy?: keyof DataType;
	/** The default sort order. If not informed, it is set to OFF. */
	defaultOrder?: Order;
	/** The maximum height of the table container (excluding the title and the
	 *  pagination controls). */
	maxTableContainerHeight?: number | string;
	// /** How to behave when a table does not fit in the parent component height.
	//  *  'GrowParent' forces the parent component to grow so that the table fits.
	//  *  'FitParent' makes the table row scrollable so that the tables fits inside
	//  *  the parent component.
	//  *  When selecting 'FitParent', the 'parentDimensions' need to be provided. */
	// overflow?: TableOverflowType;
	// /** The dimensions of the parent element. Using so that the table stretches vertically to it. */
	// parentDimensions?: ElementDimensions;
};

/**
 * A table component to display data.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Table = <DataType,>({
	title,
	columnsConfig,
	data,
	isLoading = false,
	allowSelectRows = false,
	selectedRowToolbarIcon,
	selectedRowToolbarIconLabel,
	allowCollapsibleRows = false,
	renderCollapsibleRow,
	collapsibleButtonColumnPosition = 'last',
	rowsPerPageOptions = [10, 25, 50],
	defaultRowsPerPage = rowsPerPageOptions[0] || 10,
	disablePagination = false,
	showBorder = true,
	dense: density = false,
	densityControl = false,
	rowActions = [],
	defaultOrderBy = columnsConfig[0].id,
	defaultOrder = 'off',
	maxTableContainerHeight,
}: TableProps<DataType>) => {
	const [order, setOrder] = useState<Order>(defaultOrder);
	const [orderBy, setOrderBy] = useState<keyof DataType>(defaultOrderBy);
	const [selectedRows, setSelectedRows] = useState<readonly number[]>([]);
	const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
	const [page, setPage] = useState<number>(0);
	const [dense, setDense] = useState<boolean>(density);
	const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
	const [filters, setFilters] = useState<ColumnFilterType<DataType>[]>([]);

	const toolbarRef = useRef<ReactNode>(null);
	const paginationRef = useRef<ReactNode>(null);

	/* Add an ID to each row */
	const tableData = useMemo<RowData<DataType>[]>(
		() =>
			data.map((rawRowData: DataType, rowIndex: number) => {
				const rowData: RowData<DataType> = {
					id: rowIndex,
					data: rawRowData,
				};
				return rowData;
			}),
		[data],
	);

	const filteredTableData = useMemo<RowData<DataType>[]>(() => {
		if (filters.length === 0) {
			return tableData;
		}
		let newFilteredTableData: RowData<DataType>[] = tableData;
		filters.forEach((columnFilterEntry: ColumnFilterType<DataType>) => {
			newFilteredTableData = newFilteredTableData.filter(
				(rowData: RowData<DataType>) => {
					const propertyValue: string = rowData.data[
						columnFilterEntry.property
					] as unknown as string;
					return propertyValue
						.toLowerCase()
						.includes(columnFilterEntry.filterText.toLowerCase());
				},
			);
		});
		return newFilteredTableData;
	}, [tableData, filters]);

	/* Sort the table */
	/*
	 * If you don't need to support IE11, you can replace the `stableSort` call with:
	 * 'tableData.slice().sort(getComparator(order, orderBy))'.
	 */
	const filteredAndSortedTableData = useMemo<RowData<DataType>[]>(() => {
		if (order === 'off') {
			return filteredTableData;
		} else {
			return stableSort<RowData<DataType>>(
				filteredTableData,
				getRowDataComparator<DataType>(order, orderBy),
			);
		}
	}, [filteredTableData, order, orderBy]);

	useEffect(() => {
		setPage(0);
	}, [filteredAndSortedTableData]);

	/* Toggle row selection ON or OFF */
	useEffect(() => {
		if (!allowSelectRows) {
			setSelectedRows([]);
		}
	}, [allowSelectRows]);

	useEffect(() => {
		setDense(density);
	}, [density]);

	const handleOnRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataType,
	) => {
		if (orderBy !== property) {
			setOrder('asc');
			setOrderBy(property);
		} else {
			switch (order) {
				case 'off':
					setOrder('asc');
					break;
				case 'asc':
					setOrder('desc');
					break;
				case 'desc':
					setOrder('off');
					break;
			}
		}
	};

	const handleOnSelectAllClick = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		if (event.target.checked) {
			const newSelectedRows = filteredAndSortedTableData.map<number>(
				(rowData: RowData<DataType>) => rowData.id,
			);
			setSelectedRows(newSelectedRows);
			return;
		}
		setSelectedRows([]);
	};

	const handleOnToggleSelectRow = (
		event: React.MouseEvent<unknown>,
		id: number,
	) => {
		const selectedIndex = selectedRows.indexOf(id);
		let newSelectedRows: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelectedRows = newSelectedRows.concat(selectedRows, id);
		} else if (selectedIndex === 0) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
		} else if (selectedIndex === selectedRows.length - 1) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedRows = newSelectedRows.concat(
				selectedRows.slice(0, selectedIndex),
				selectedRows.slice(selectedIndex + 1),
			);
		}

		setSelectedRows(newSelectedRows);
	};

	const handleOnPageChange = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleOnRowsPerPageChange = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleOnDenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleOnToggleFilter = () => {
		setIsFilterActive((prev: boolean) => !prev);
	};

	const handleOnColumnFiltersChange = (
		newFilters: ColumnFilterType<DataType>[],
	) => {
		setFilters(newFilters);
	};

	// const maxTableContainerHeight = useMemo<number | undefined>(() => {
	// 	if (overflow === TableOverflowType.GrowParent) {
	// 		return undefined;
	// 	}
	// 	const toolbarHeight: number = toolbarRef.current?.offsetHeight || 0;
	// 	const paginationHeight: number = paginationRef.current?.offsetHeight || 0;
	// 	const maxHeight =
	// 		parentDimensions.height - toolbarHeight - paginationHeight;
	// 	if (maxHeight < 0) {
	// 		return undefined;
	// 	}
	// 	/* Allow some extra room, so that the parent does not overflow. */
	// 	return maxHeight - 20;
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [parentDimensions.height, overflow]);

	return (
		<MuiBox
			sx={{
				width: '100%',
				height: maxTableContainerHeight,
			}}
		>
			<OptionalMuiPaper
				show={showBorder}
				sx={{
					width: '100%',
					mb: 2,
					overflow: 'hidden',
					height: '100%',
					boxShadow:
						'0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
				}}
			>
				<TableToolbar
					ref={toolbarRef}
					title={title}
					/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
					// @ts-ignore
					columnsConfig={columnsConfig}
					selectedRowIcon={selectedRowToolbarIcon}
					selectedRowIconLabel={selectedRowToolbarIconLabel}
					numberOfSelectedRows={selectedRows.length}
					isFilterActive={isFilterActive}
					onToggleFilter={handleOnToggleFilter}
				/>
				<MuiTableContainer
					sx={{
						height: 'calc( 100% - 116px )',
						maxHeight: 'calc( 100% - 116px )',
					}}
				>
					<MuiTable
						aria-labelledby="table"
						size={dense ? 'small' : 'medium'}
						stickyHeader={true}
					>
						<TableHead
							columnsConfig={columnsConfig}
							allowSelectRows={allowSelectRows}
							numberOfSelectedRows={selectedRows.length}
							order={order}
							orderBy={orderBy}
							numberOfRows={filteredAndSortedTableData.length}
							allowCollapsibleRows={allowCollapsibleRows}
							collapsibleButtonColumnPosition={collapsibleButtonColumnPosition}
							onRequestSort={handleOnRequestSort}
							onSelectAllClick={handleOnSelectAllClick}
							rowActions={rowActions}
							isFilterActive={isFilterActive}
							onColumnFiltersChange={handleOnColumnFiltersChange}
						/>
						<TableBody
							tableData={filteredAndSortedTableData}
							columnsConfig={columnsConfig}
							isLoading={isLoading}
							allowSelectRows={allowSelectRows}
							selectedRows={selectedRows}
							allowCollapsibleRows={allowCollapsibleRows}
							renderCollapsibleRow={renderCollapsibleRow || null}
							collapsibleButtonColumnPosition={collapsibleButtonColumnPosition}
							page={page}
							rowsPerPage={rowsPerPage}
							dense={dense}
							onToggleSelectRow={handleOnToggleSelectRow}
							rowActions={rowActions}
							disablePagination={disablePagination}
						/>
					</MuiTable>
				</MuiTableContainer>
				{!disablePagination && (
					<MuiTablePagination
						ref={paginationRef}
						rowsPerPageOptions={rowsPerPageOptions}
						component="div"
						count={filteredAndSortedTableData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleOnPageChange}
						onRowsPerPageChange={handleOnRowsPerPageChange}
					/>
				)}
			</OptionalMuiPaper>

			{densityControl && (
				<MuiFormControlLabel
					control={<MuiSwitch checked={dense} onChange={handleOnDenseChange} />}
					label="Dense padding"
				/>
			)}
		</MuiBox>
	);
};

type OptionalMuiPaperProps = MuiPaperProps & {
	show?: boolean;
};

const OptionalMuiPaper = ({
	show = true,
	...muiPaperProps
}: OptionalMuiPaperProps) => {
	if (show) {
		return <MuiPaper {...muiPaperProps} />;
	} else {
		const { children, className } = muiPaperProps;
		return <div className={className}>{children}</div>;
	}
};
