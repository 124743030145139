// file UserIcon.tsx
import { SvgIcon } from '@mui/material';

const PlusIcon = (props: any) => {
	return (
		<SvgIcon {...props}>
			<path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" />
		</SvgIcon>
	);
};
export default PlusIcon;
