import React, { useState } from 'react';
import { IconButton as MuiIconButton, Paper as MuiPaper } from '@mui/material';
import { BiSearchAlt } from 'react-icons/bi';
import { TextField, TextFieldProps } from '../TextField';
import styles from './SearchTextField.module.scss';

export type InputSearchProps = TextFieldProps & {
	initialValue?: string;
	// eslint-disable-next-line no-unused-vars
	onSubmitSearch?: (searchText: string) => void;
};

export const SearchTextField = ({
	initialValue = '',
	onSubmitSearch,
	helperText,
}: InputSearchProps) => {
	const [searchText, setSearchText] = useState<string>(initialValue);

	const handleOnSearchTextChange = (text: string) => {
		setSearchText(text);
	};

	const handleOnFormSubmit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (onSubmitSearch) {
			onSubmitSearch(searchText);
		}
	};

	return (
		<MuiPaper
			elevation={0}
			component="form"
			onSubmit={handleOnFormSubmit}
			sx={{
				backgroundColor: 'transparent',
			}}
		>
			<TextField
				id="search"
				label="Search"
				placeholder="Search"
				errorText={helperText ? helperText : undefined}
				helperText={helperText ? helperText : undefined}
				className={styles.searchText}
				onChange={handleOnSearchTextChange}
				endIcon={
					<MuiIconButton type="submit" aria-label="search">
						<BiSearchAlt />
					</MuiIconButton>
				}
			/>
		</MuiPaper>
	);
};
