import { ThemeOptions } from '../components/ThemeProvider/ThemeOptions';
import {
	AuxiliaryDanger,
	AuxiliarySuccess,
	AuxiliaryWarning,
	ColdNeutral,
	PilabsBlue,
	white,
} from '../colors';
import { pilabsLogo } from '../logos';

export const pilabsTheme: ThemeOptions = {
	name: 'pilabs',
	logo: pilabsLogo,
	palette: {
		primary: {
			main: PilabsBlue[500],
			contrastText: white,
		},
		info: {
			main: ColdNeutral[200],
			contrastText: white,
		},
		success: {
			main: AuxiliarySuccess[500],
			contrastText: white,
		},
		warning: {
			main: AuxiliaryWarning[500],
			contrastText: white,
		},
		error: {
			main: AuxiliaryDanger[700],
			contrastText: white,
		},
	},
};
