import React from 'react';
import MuiTooltip from '@mui/material/Tooltip';

export type TooltipPlacement =
	| 'bottom-end'
	| 'bottom-start'
	| 'bottom'
	| 'left-end'
	| 'left-start'
	| 'left'
	| 'right-end'
	| 'right-start'
	| 'right'
	| 'top-end'
	| 'top-start'
	| 'top';

export type TooltipProps = {
	/**
	 * Tooltip title. Zero-length titles string are never displayed.
	 */
	title: NonNullable<React.ReactNode>;
	/**
	 * Tooltip placement.
	 * @default 'bottom'
	 */
	placement?: TooltipPlacement;
	/**
	 * Tooltip reference element.
	 */
	children: React.ReactElement<any, any>;
	/**
	 * The number of milliseconds to wait before showing the tooltip
	 * @default 100
	 */
	enterDelay?: number;
	/**
	 * The number of milliseconds after the user stops touching an element before hiding the tooltip
	 * @default 1500
	 */
	leaveDelay?: number;
};

/**
 * A component that renders a button.
 */
export const Tooltip = ({ children, ...props }: TooltipProps) => (
	<MuiTooltip {...props} arrow>
		<span>{children}</span>
	</MuiTooltip>
);
