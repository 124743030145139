import React, { ReactNode } from 'react';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { ColumnConfig, RowAction, RowData } from './Table';
import { TableBodyRow } from './TableBodyRow';

export type TableBodyProps<DataType> = {
	/** The data to be displayed as rows in the table. */
	tableData: RowData<DataType>[];
	/** The configuration for the table columns. */
	columnsConfig: readonly ColumnConfig<DataType>[];
	/** Whether the table data is still loading. */
	isLoading?: boolean;
	/** Whether table rows can be selected. */
	allowSelectRows: boolean;
	/** The identifiers for the currently selected table rows. */
	selectedRows: readonly number[];
	/** Whether the table rows can be collapsed. */
	allowCollapsibleRows: boolean;
	/** An optional function that specifies how to render a collapsible row. Required when setting __allowCollapsibleRows__ to __true__. */
	renderCollapsibleRow:
		| ((rowData: RowData<DataType>) => ReactNode | string)
		| null;
	/**
	 * Whether the collapsible button will be on the first (after row selection toggle) or the last column.
	 * @default last
	 */
	collapsibleButtonColumnPosition?: 'first' | 'last';
	/** The number of the currently selected page. */
	page: number;
	/** The number of rows per page. */
	rowsPerPage: number;
	/** Whether to disable the table pagination. All rows will be displayed at once. */
	disablePagination?: boolean;
	/** Whether the table should be displayed as a dense layout. */
	dense: boolean;
	/** A callback function for a table row is selected or unselected. */
	onToggleSelectRow: (event: React.MouseEvent<unknown>, rowId: number) => void;
	/** A list of icon buttons to be displayed at the end of each row. */
	rowActions: RowAction<DataType>[];
	/** The className prop to the applied to the table body. */
	className?: string;
};

export const TableBody = <DataType,>({
	tableData,
	columnsConfig,
	isLoading,
	allowSelectRows,
	selectedRows,
	allowCollapsibleRows,
	renderCollapsibleRow,
	collapsibleButtonColumnPosition = 'last',
	page,
	rowsPerPage,
	disablePagination,
	dense,
	onToggleSelectRow,
	rowActions,
	className,
}: TableBodyProps<DataType>) => {
	/* Avoid a layout jump when reaching the last page with empty rows */
	const numberOfEmptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

	const wholeRowColSpan =
		columnsConfig.length +
		(allowSelectRows ? 1 : 0) +
		(allowCollapsibleRows ? 1 : 0) +
		(rowActions.length > 0 ? 1 : 0);

	const pagedTableData = disablePagination
		? tableData
		: tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	return (
		<MuiTableBody className={className}>
			{isLoading ? (
				<MuiTableRow>
					<MuiTableCell colSpan={wholeRowColSpan}>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<MuiCircularProgress />
						</div>
					</MuiTableCell>
				</MuiTableRow>
			) : (
				<>
					{pagedTableData.map((rowData: RowData<DataType>) => (
						<TableBodyRow
							key={rowData.id}
							columnsConfig={columnsConfig}
							rowData={rowData}
							allowSelectRows={allowSelectRows}
							selectedRows={selectedRows}
							allowCollapsibleRows={allowCollapsibleRows}
							renderCollapsibleRow={renderCollapsibleRow}
							collapsibleButtonColumnPosition={collapsibleButtonColumnPosition}
							onToggleSelectRow={onToggleSelectRow}
							rowActions={rowActions}
						/>
					))}
					{numberOfEmptyRows > 0 && (
						<MuiTableRow
							style={{
								height: (dense ? 33 : 53) * numberOfEmptyRows,
							}}
						>
							<MuiTableCell colSpan={wholeRowColSpan} />
						</MuiTableRow>
					)}
				</>
			)}
		</MuiTableBody>
	);
};
