import React, { ReactNode } from 'react';
import styles from './MaxWidthContainer.module.scss';

export type MaxWidthContainerProps = {
	/** The components to be rendered as children of this component. */
	children: ReactNode;
};

/**
 * A component that renders a container element that provides a standard maximum width for the layout.
 * This component can be used, for example, for aligning elements from the menubar with elements from
 * another part of the application.
 */
export const MaxWidthContainer = ({ children }: MaxWidthContainerProps) => {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.maxWidth}>{children}</div>
			</div>
		</>
	);
};
