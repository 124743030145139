import React, { FC } from 'react';
import Footer from '@chaptesi/licenses/components/Footer';
import Header from './Header';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ILayoutProps {
	children?: JSX.Element | Array<JSX.Element>;
}

const useStyles = makeStyles((theme: any) => ({
	root: {
		'& button.MuiButton-textPrimary:hover': {
			backgroundColor: 'rgba(0, 186, 255, 0.2)',
		},
		'& button.MuiButton-textSecondary:hover': {
			backgroundColor: 'rgba(245, 0, 87, 0.2)',
		},
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100vw',
		height: '100vh',
	},
	main: {
		width: '100%',
		height: '100%',
		flexGrow: 1,
		overflow: 'auto',
	},

	paddingTop: {
		paddingTop: '3.5rem',
		height: '100%',
	},

	hideContentBeneath: {
		position: 'sticky',
		top: '56px',
		left: 0,
		width: 'calc(100% - 20px)',
		height: '66px',
		backgroundColor: 'white',
		Zindex: 1,
	},
	footer: {
		flexShrink: 0,
		width: '100%',
		backgroundColor: '#F2F4F5',
	},
}));

const Layout: FC<ILayoutProps> = function ({ children }) {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Header />
			<div className={classes.main}>
				<Container className={classes.paddingTop}>
					<>{children}</>
				</Container>
			</div>
			<Footer className={classes.footer} />
		</div>
	);
};

export default Layout;
