// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dwPZvKg7TvOKDHbV8CaO7g\\=\\={width:100%;max-width:100%;height:100%;display:flex;flex-direction:row;justify-content:center}._1s-GNIOygpGNmEyvveQpTg\\=\\={width:calc(100% - 40px);max-width:calc(100% - 40px);height:100%;margin:0 20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dwPZvKg7TvOKDHbV8CaO7g==",
	"maxWidth": "_1s-GNIOygpGNmEyvveQpTg=="
};
export default ___CSS_LOADER_EXPORT___;
