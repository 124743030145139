// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j7bwLTOIMTSs1MfiBzEbWQ\\=\\={display:flex;align-items:center;margin-top:0;color:#67808c;font-size:16px;line-height:22px;font-weight:600}.erAIC0Z\\+PMx8dUm7riJQQg\\=\\={display:flex;align-items:center}.A9nd9MzKYoUYf0S7p2GK1A\\=\\=,._8xA-KF3nE7V08GScA2fbVg\\=\\={height:100%;font-size:20px;text-align:center;vertical-align:middle}._8xA-KF3nE7V08GScA2fbVg\\=\\={color:#2b3438;margin:0 16px}.KUG01CuDebs14mbYnB3w7A\\=\\={text-decoration:none;color:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "j7bwLTOIMTSs1MfiBzEbWQ==",
	"menuItem": "erAIC0Z+PMx8dUm7riJQQg==",
	"breadcrumbHome": "A9nd9MzKYoUYf0S7p2GK1A==",
	"arrow": "_8xA-KF3nE7V08GScA2fbVg==",
	"clearAnchor": "KUG01CuDebs14mbYnB3w7A=="
};
export default ___CSS_LOADER_EXPORT___;
