import React, { useEffect, useState } from 'react';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import MuiBox from '@mui/material/Box';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiTextField from '@mui/material/TextField';
import { visuallyHidden } from '@mui/utils';
import { Order } from './common';
import { ColumnConfig, ColumnFilterType, RowAction } from './Table';

type TableHeadProps<DataType> = {
	/** The configuration for the table columns. */
	columnsConfig: readonly ColumnConfig<DataType>[];
	/** Whether table rows can be selected. */
	allowSelectRows: boolean;
	/** The number of currently selected rows. */
	numberOfSelectedRows: number;
	/** The order currently used for sorting the table (ascendant or descendant). */
	order: Order;
	/** The column by which to sort the table. Stores the column identifier. */
	orderBy: keyof DataType;
	/** The number of rows in the table. */
	numberOfRows: number;
	/** Whether the table rows can be collapsed. */
	allowCollapsibleRows: boolean;
	/**
	 * Whether the collapsible button will be on the first (after row selection toggle) or the last column.
	 * @default last
	 */
	collapsibleButtonColumnPosition?: 'first' | 'last';
	/** A callback function for when sorting a specific column is requested. */
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof DataType,
	) => void;
	/** A callback function for when the 'select all' checkbox is clicked. */
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	/** A list of icon buttons to be displayed at the end of each row. */
	rowActions: RowAction<DataType>[];
	/** Whether the table filter is active. */
	isFilterActive: boolean;
	/** A callback function for when the filter for a property changes. */
	onColumnFiltersChange: (filters: ColumnFilterType<DataType>[]) => void;
};

/**
 * The Head component for the table.
 */
export const TableHead = <DataType,>({
	columnsConfig,
	allowSelectRows,
	numberOfSelectedRows,
	order,
	orderBy,
	numberOfRows,
	allowCollapsibleRows,
	collapsibleButtonColumnPosition = 'last',
	onRequestSort,
	onSelectAllClick,
	rowActions,
	isFilterActive,
	onColumnFiltersChange,
}: TableHeadProps<DataType>) => {
	const [filters, setFilters] = useState<ColumnFilterType<DataType>[]>([]);

	const createSortHandler =
		(property: keyof DataType) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	const getSortingProps = (
		headCell: ColumnConfig<DataType>,
		order: Order,
		orderBy: keyof DataType,
	): {
		sortActive: boolean;
		sortDirection: 'asc' | 'desc' | false;
		direction: 'asc' | 'desc' | undefined;
	} => {
		let sortActive: boolean;
		let sortDirection: 'asc' | 'desc' | false;
		let direction: 'asc' | 'desc' | undefined;
		if (order === 'off') {
			sortActive = false;
			sortDirection = false;
			direction = undefined;
		} else if (orderBy === headCell.id) {
			sortActive = true;
			sortDirection = order;
			direction = order;
		} else {
			sortActive = false;
			sortDirection = false;
			direction = undefined;
		}
		return { sortActive, sortDirection, direction };
	};

	const handleOnChangeFilter = (property: keyof DataType) => {
		return (event: React.ChangeEvent<HTMLInputElement>) => {
			const filterText = event.target?.value || '';
			setFilters((prevFilters) => {
				const newFilters = [...prevFilters];
				const newFilterEntry: ColumnFilterType<DataType> = {
					property: property,
					filterText: filterText,
				};
				const propertyIndex = newFilters
					.map(
						(columnFilter: ColumnFilterType<DataType>) => columnFilter.property,
					)
					.indexOf(property);
				if (propertyIndex === -1) {
					return newFilters.concat(newFilterEntry);
				} else {
					newFilters[propertyIndex] = newFilterEntry;
					return newFilters;
				}
			});
		};
	};

	useEffect(() => {
		if (!isFilterActive) {
			setFilters([]);
		}
	}, [isFilterActive]);

	useEffect(() => {
		onColumnFiltersChange(filters);
	}, [filters]);

	let firstRowStyle = {};
	if (isFilterActive) {
		firstRowStyle = {
			'& > *': {
				borderBottom: 'unset !important',
			},
		};
	}

	return (
		<MuiTableHead>
			<MuiTableRow sx={firstRowStyle}>
				{allowSelectRows && (
					<MuiTableCell padding="checkbox">
						<MuiCheckbox
							color="primary"
							indeterminate={
								numberOfSelectedRows > 0 && numberOfSelectedRows < numberOfRows
							}
							checked={
								numberOfRows > 0 && numberOfSelectedRows === numberOfRows
							}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': 'select all rows',
							}}
						/>
					</MuiTableCell>
				)}

				{allowCollapsibleRows &&
					collapsibleButtonColumnPosition === 'first' && <MuiTableCell />}

				{columnsConfig.map((headCell: ColumnConfig<DataType>) => {
					const { sortActive, sortDirection, direction } = getSortingProps(
						headCell,
						order,
						orderBy,
					);
					const { paddingLeft, paddingRight } = headCell;
					const cssPadding = { paddingLeft, paddingRight };
					return (
						<MuiTableCell
							key={`${headCell.id}`}
							align={headCell.align || 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							style={headCell.disablePadding ? undefined : cssPadding}
							sortDirection={sortDirection}
							sx={{
								fontWeight: '700',
							}}
						>
							<MuiTableSortLabel
								active={sortActive}
								direction={direction}
								sx={{
									color: 'text.primary',
									':hover': {
										color: 'text.primary',
									},
									':focus': {
										color: 'text.primary',
									},
								}}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{sortActive ? (
									<MuiBox component="span" sx={visuallyHidden}>
										{order === 'asc' ? 'sorted ascending' : 'sorted descending'}
									</MuiBox>
								) : null}
							</MuiTableSortLabel>
						</MuiTableCell>
					);
				})}

				{allowCollapsibleRows && collapsibleButtonColumnPosition === 'last' && (
					<MuiTableCell />
				)}

				{rowActions.length > 0 && <MuiTableCell />}
			</MuiTableRow>

			{/* The collapsible filter row */}
			{isFilterActive ? (
				<MuiTableRow
					sx={{
						'& > *': {
							paddingBottom: 0,
							paddingTop: 0,
						},
					}}
				>
					{allowSelectRows && <MuiTableCell />}
					{allowCollapsibleRows &&
						collapsibleButtonColumnPosition === 'first' && <MuiTableCell />}
					{columnsConfig.map(
						(columnConfig: ColumnConfig<DataType>, columnIndex: number) => {
							const columnFilterText: string =
								filters.filter(
									(filterEntry: ColumnFilterType<DataType>) =>
										filterEntry.property === columnConfig.id,
								)[0]?.filterText || '';
							return (
								<MuiTableCell key={columnIndex}>
									{/*<MuiCollapse in={isFilterActive} timeout="auto" unmountOnExit>*/}
									<MuiBox sx={{ marginBottom: 0 }}>
										{columnConfig.filter ? (
											<MuiTextField
												value={columnFilterText}
												variant="standard"
												size="small"
												fullWidth
												inputProps={{
													style: {
														textAlign: columnConfig.align,
													},
												}}
												sx={{
													visibility: columnConfig.filter
														? 'visible'
														: 'hidden',
													padding: 0,
												}}
												onChange={handleOnChangeFilter(columnConfig.id)}
											/>
										) : null}
									</MuiBox>
									{/*</MuiCollapse>*/}
								</MuiTableCell>
							);
						},
					)}
					{allowCollapsibleRows &&
						collapsibleButtonColumnPosition === 'last' && <MuiTableCell />}
					{rowActions.length > 0 && <MuiTableCell />}
				</MuiTableRow>
			) : null}
		</MuiTableHead>
	);
};
