// import React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { signOut } from '../../actions/AuthActions';
import UserIcon from '../../assets/icons/UserIcon';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	menu: {
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		justifySelf: 'stretch',
		marginLeft: '2rem',
	},
	menuIcon: {
		marginLeft: '1.5rem',
		padding: '0.5rem',
		color: `${theme.palette.grey['400']}`,
		transition: 'color 0.3s linear',
	},
	headerLink: {
		display: 'flex',
		fontSize: '0.75rem',
		alignItems: 'center',
		borderBottom: `transparent solid 4px`,
		color: theme.palette.grey['500'],
		fontWeight: 500,
		letterSpacing: '1.25px',
		transition: 'color .3s linear',
		borderLeft: `${theme.palette.grey['200']} solid 1px`,
		paddingLeft: '1.5rem',
		'&:not(:first-child)': {
			marginLeft: '1.875rem',
		},
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&.header-selected': {
			color: theme.palette.primary.main,
			borderBottom: `${theme.palette.primary.main} solid 4px`,
		},
	},
}));

const ProfileMenu = () => {
	const classes = useStyles();

	const dispatch = useDispatch();

	return (
		<>
			<div id="menu-toggle" className={`${classes.menu}`}>
				<Link
					to=""
					onClick={() => {
						dispatch(signOut());
					}}
					className={classes.headerLink}
				>
					LOGOUT
				</Link>
				<div className={`${classes.menuIcon}`}>
					<UserIcon />
				</div>
			</div>
		</>
	);
};

export default ProfileMenu;
