import React from 'react';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

export type CheckboxProps = {
	/** Whether the checkbox is checked. */
	isChecked: boolean;
	/** Whether the checkbox is partially checked. */
	isPartiallyChecked?: boolean;
	/** The text to display as a label. */
	label?: string;
	/** The label placement. */
	labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
	/** Whether the checkbox is disabled. */
	disabled?: boolean;
	/** A callback function executed when the checkbox is clicked. */
	onChange?: (checked: boolean) => void;
	/** The names of optional CSS classes to be added to the component. */
	className?: string;
};

/**
 * A component that renders a checkbox.
 */
export const Checkbox = ({
	isChecked,
	isPartiallyChecked = false,
	label,
	labelPlacement = 'end',
	disabled = false,
	onChange,
	className,
}: CheckboxProps) => {
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event.target.checked);
		}
	};

	const checkbox = (
		<MuiCheckbox
			checked={isChecked}
			indeterminate={isChecked && isPartiallyChecked}
			disabled={disabled}
			className={className}
			onChange={handleOnChange}
		/>
	);
	if (label) {
		return (
			<>
				<MuiFormControlLabel
					control={checkbox}
					label={label}
					labelPlacement={labelPlacement}
				/>
			</>
		);
	} else {
		return <>{checkbox}</>;
	}
};
