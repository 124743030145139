import React from 'react';
import MuiLinearProgress from '@mui/material/LinearProgress';
import MuiTypography from '@mui/material/Typography';
import classNames from 'classnames';
import styles from './ProgressIndicator.module.scss';

export type ProgressIndicatorProps = {
	/** The percentage value of the progress indicator. If must be a value between 0 and 100. */
	value: number;
	/** Whether a label should be displayed with the current progress percentage. */
	label?: boolean;
	/** The names of optional CSS classes to be added to the component. */
	className?: string;
};

/**
 * A component that renders a progress indicator.
 */
export const ProgressIndicator = ({
	value,
	label = false,
	className,
}: ProgressIndicatorProps) => {
	let properValue = value;
	if (value < 0) {
		properValue = 0;
	}
	if (value > 100) {
		properValue = 100;
	}
	return (
		<>
			<div className={classNames([styles.container, className])}>
				<div className={styles.progressBar}>
					<MuiLinearProgress variant="determinate" value={properValue} />
				</div>
				{label && (
					<div className={styles.label}>
						<MuiTypography>{`${Math.round(properValue)}%`}</MuiTypography>
					</div>
				)}
			</div>
		</>
	);
};
