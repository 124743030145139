import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { refreshAuth } from './auth';

/* REQUEST INTERCEPTOR */
axios.interceptors.request.use(async (request) => {
	if (request.url?.endsWith('oauth2/token') || request.url?.endsWith('login') || request.url?.endsWith('refresh')) {
		return request;
	}
	const token = window.localStorage.getItem('idToken');
	const decodedToken = token ? jwtDecode<JwtPayload>(token) : null;
	if (decodedToken && decodedToken.exp && decodedToken.exp > new Date().getTime() / 1000 + 5) {
		request.headers
			? (request.headers.Authorization = `Bearer ${token}`)
			: (request.headers = { Authorization: `Bearer ${token}` });
	} else {
		return await refreshAuth()
			.then(({ idToken, accessToken }: any) => {
				request.headers
					? (request.headers.Authorization = `Bearer ${idToken}`)
					: (request.headers = { Authorization: `Bearer ${idToken}` });
				if (request.url?.endsWith('logout')) {
					request.data.idToken = idToken;
					request.data.accessToken = accessToken;
				}
				return request;
			})
			.catch((error) => {
				throw error;
			});
	}
	return request;
});

/* RESPONSE INTERCEPTOR */
axios.interceptors.response.use((response) => {
	// Add interceptions to responses here.
	return response;
});

export default axios;
