import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menubar } from '../Menubar';
import { TopBar } from '../TopBar';
import { Routes, RouteItem } from '../../types';

export type RouteHeaderProps = {
	username: string;
	userRoles: string[];
	routes: Routes;
	children?: React.ReactNode[];
};

export const RouteHeader = ({
	username,
	userRoles,
	routes,
}: RouteHeaderProps) => {
	const [menu, setMenu] = useState<RouteItem[]>([]);
	const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0);

	const navigate = useNavigate();
	const location = useLocation();

	const handleOnChangeSelectedMenuItem = (selectedItem: number) => {
		setSelectedMenuItem(selectedItem);
		navigate(menu[selectedItem].uri);
	};

	useEffect(() => {
		const currentRoute = menu.filter(
			(route: RouteItem) => route.uri === window.location.pathname,
		)[0];
		setSelectedMenuItem(menu.indexOf(currentRoute));
	}, [location, menu]);

	useEffect(() => {
		/* The menu only contains entries that the user has access to */
		const routeKeys = Object.keys(routes);
		const newMenu: RouteItem[] = routeKeys.reduce(
			(previousValue: RouteItem[], currentValue: string) => {
				const routeItem: RouteItem = routes[currentValue];
				if (routeItem.roles.some((role: string) => userRoles.includes(role))) {
					return [
						...previousValue,
						{
							roles: routeItem.roles,
							label: routeItem.label,
							uri: routeItem.uri,
						},
					];
				} else {
					return previousValue;
				}
			},
			[],
		);
		setMenu(newMenu);
	}, [routes, userRoles]);

	return (
		<>
			<TopBar>
				<Menubar
					menu={menu}
					selectedItem={selectedMenuItem}
					logo={true}
					username={username}
					onChange={handleOnChangeSelectedMenuItem}
				/>
			</TopBar>
		</>
	);
};
