import { RowData } from './Table';

export type Order = 'asc' | 'desc' | 'off';

/*
 This method is created for cross-browser compatibility. If you don't
 need to support IE11, you can use Array.prototype.sort() directly.
 */
export const stableSort = <T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number,
): T[] => {
	const stabilizedThis = array.map<[T, number]>(
		(element: T, index: number) => [element, index] as [T, number],
	);
	stabilizedThis.sort((a: [T, number], b: [T, number]) => {
		const order: number = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map<T>((element: [T, number]) => element[0]);
};

type RowDataComparatorType<DataType> = (
	r1: RowData<DataType>,
	r2: RowData<DataType>,
) => number;

export const getRowDataComparator = <DataType>(
	order: Order,
	orderBy: keyof DataType,
): RowDataComparatorType<DataType> => {
	const genericComparator: GenericComparatorType<DataType, keyof DataType> =
		getGenericComparator<DataType, keyof DataType>(order, orderBy);
	return (r1: RowData<DataType>, r2: RowData<DataType>) =>
		genericComparator(r1.data, r2.data);
};

type GenericComparatorType<T, Key extends keyof any> = (a: T, b: T) => number;

export const getGenericComparator = <T, Key extends keyof T>(
	order: Order,
	orderBy: Key,
): GenericComparatorType<T, Key> => {
	return (a: T, b: T) =>
		order === 'asc'
			? compareGenericType<T>(a, b, orderBy)
			: compareGenericType<T>(b, a, orderBy);
};

const compareGenericType = <T>(a: T, b: T, orderBy: keyof T): number => {
	if (isNan(a[orderBy]) && !isNan(b[orderBy])) {
		return -1;
	}
	if (!isNan(a[orderBy]) && isNan(b[orderBy])) {
		return 1;
	}
	if (isNan(a[orderBy]) && isNan(b[orderBy])) {
		return 0;
	}
	if (a[orderBy] < b[orderBy]) {
		return -1;
	}
	if (a[orderBy] > b[orderBy]) {
		return 1;
	}
	return 0;
};

const isNan = (input: any) => {
	return input === undefined || input === null;
};
