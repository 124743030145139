import React, { useEffect } from 'react';
import Spinner from '../accessories/Spinner';
import { AUTH_BASE_URL } from '../../api/constants';

const Loading = ({ redirect = false }: any) => {
	useEffect(() => {
		if (redirect) window.open(`${AUTH_BASE_URL}/login/?redirect_uri=${window.origin}`, '_self');
	}, [redirect]);
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
			<Spinner size={5} />
		</div>
	);
};

export default Loading;
