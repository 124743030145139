import { ICreateGroup, IUpdateGroup, IListGroups } from './IActions';
import keys from './ActionTypeKeys';
import { createGroup, updateGroup, listGroups } from '../api/groups';

export const listGroupsAction = (): IListGroups => ({
	type: keys.LIST_GROUPS,
	payload: listGroups(),
});

export const updateGroupAction = (group: FormData): IUpdateGroup => ({
	type: keys.UPDATE_GROUP,
	payload: updateGroup(group),
});

export const createGroupAction = (group: FormData): ICreateGroup => ({
	type: keys.CREATE_GROUP,
	payload: createGroup(group),
});
