// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kX5g0YUhCoV0kkDSak2ujw\\=\\={height:48px;display:flex;flex-direction:row;justify-content:flex-start;align-items:center}.oA9Vr9Xv08jMt3wixkzOYQ\\=\\={height:48px;display:inline-block;padding:0 16px;line-height:48px !important;color:var(--cold-neutral-color);text-transform:uppercase;box-sizing:border-box;white-space:nowrap;cursor:pointer}.oA9Vr9Xv08jMt3wixkzOYQ\\=\\=:hover{color:var(--primary-color)}._5se\\+NIUy6HIk6LzOwUfJNA\\=\\={color:var(--primary-color);border-bottom:4px solid var(--primary-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kX5g0YUhCoV0kkDSak2ujw==",
	"menuItem": "oA9Vr9Xv08jMt3wixkzOYQ==",
	"selected": "_5se+NIUy6HIk6LzOwUfJNA=="
};
export default ___CSS_LOADER_EXPORT___;
