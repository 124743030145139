import React from 'react';
import { Typography as MuiTypography } from '@mui/material';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { ColdNeutral } from '../../colors';
import styles from './TabMenu.module.scss';

export type TabMenuEntry = {
	label: string;
};

export type TabMenuProps = {
	/** A list containing the menu items to be displayed. */
	menu: TabMenuEntry[];
	/** The index of the currently selected item. */
	selectedItem: number;
	/** A callback function that informs the index of the new selected item. */
	onChange: (selectedItem: number) => void;
};

/**
 * A component that renders a Tab menu.
 */
export const TabMenu = ({ menu, selectedItem, onChange }: TabMenuProps) => {
	const theme = useMuiTheme();

	const handleChangeMenuItem = (menuItem: number) => () => {
		if (onChange) {
			onChange(menuItem);
		}
	};

	const themeStyle = {
		'--primary-color': theme.palette.primary.main,
		'--cold-neutral-color': ColdNeutral[500],
		fontFamily: theme.typography.fontFamily,
		fontSize: theme.typography.fontSize,
		lineHeight: '16px',
		letterSpacing: '1.25px',
	} as React.CSSProperties;

	return (
		<>
			<div className={styles.container}>
				{menu.map((menuEntry: TabMenuEntry, index: number) => (
					<MuiTypography
						key={index}
						component="div"
						className={classNames([
							styles.menuItem,
							{ [styles.selected]: selectedItem === index },
						])}
						sx={themeStyle}
						onClick={handleChangeMenuItem(index)}
					>
						{menuEntry.label}
					</MuiTypography>
				))}
			</div>
		</>
	);
};
